import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

import Button from '@/components/Button';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import Text from '@/components/Text';
import { useProfile } from '@/hooks/useProfile';

const PageNotFound = () => {
  const { data, isLoading } = useProfile();
  if (isLoading) return <LoadingPlaceholder />;
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6">
      <Text variant="h3">404 - Page Not Found</Text>
      {data && (
        <Link href="/dashboard">
          <Button prefixIcon={faArrowLeft}>Go back to dashboard</Button>
        </Link>
      )}
      {!data && (
        <Link href="/dashboard/auth/signin">
          <Button prefixIcon={faArrowLeft}>Go to sign in</Button>
        </Link>
      )}
    </div>
  );
};

export default PageNotFound;
